












import { Component, Vue } from 'vue-property-decorator';
import {Settings} from "@/models/Settings";
import {settingsService} from "@/services";

@Component({
  components: {
  },
})
export default class LockView extends Vue {

  settings: Settings = {} as Settings;

  async created(): Promise<void> {
    this.settings = await settingsService.getSettings();
  }

}
